<template>
  <div class="account-detail">
    <div class="account-detail-main">
      <park-header></park-header>
      <van-field v-model="userInfo.account" readonly input-align="right" label="账号" />
      <van-field v-model="userInfo.userTypeName" readonly input-align="right" label="账号类型" />
      <!--<van-field v-model="info.out_time" input-align="right" label="密码" />-->
      <!--<van-field v-model="info.out_time" type="password" readonly-->
                 <!--@click="doModify"-->
                 <!--input-align="right" label="修改密码" />-->
      <van-cell is-link :disabled="true" @click="doModify">修改密码</van-cell>
      <div class="account-detail-bnt-wrap">
        <div class="bnt-top-wrap">
          <!--<van-button round  type="info" @click="clearLocalStorage">清除缓存</van-button>-->
          <van-button round type="info" @click="doLogout" block>退出</van-button>
        </div>
        <!--<div class="bnt-bottom-wrap">-->
          <!--<van-button type="info" round block>关于和帮助</van-button>-->
        <!--</div>-->
      </div>
    </div>
    <van-dialog v-model="showModifyDialog" title="修改密码"
      :showConfirmButton="false"
      :showCancelButton="false">
      <van-form @submit="doResetPass" @failed="onFail"ref="form">
        <van-field
          v-model="form.password"
          type="password"
          name="password"
          label="密码"
          placeholder="请输入密码"
          :rules="[{ required: true}]"
        />
        <van-field
          v-model="form.new_password"
          type="password"
          name="new_password"
          label="新密码"
          placeholder="请输入新密码"
          :rules="[{ required: true}]"
        />
        <van-field
          v-model="form.re_new_password"
          type="password"
          name="re_new_password"
          label="确认新密码"
          placeholder="请再次输入新密码"
          :rules="[{ required: true}, { validator: checkPassword, message: '两次密码不一致'}]"
        />
        <div class="modify-footer">
          <van-button round @click="doCancel" native-type="button">取消</van-button>
          <van-button round :loading="loading"  type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import parkHeader from '../../public/park-header'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as md5 from 'md5'
// import * as mqtt from '../../api/wsconnect.js'
// import * as util from '../../api/util.js'
import { mapState } from 'vuex'
export default {
  name: 'account-detail',
  components: {
    parkHeader
  },
  data () {
    return {
      info: {},
      showModifyDialog: false,
      loading: false,
      form: {
        password: '',
        new_password: '',
        re_new_password: ''
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    checkPassword (value) {
      return this.form.new_password == value
    },
    clearLocalStorage() {
      localStorage.clear()
      this.$router.replace('/login')
    },
    doModify () {
      this.showModifyDialog = true
      console.log('this.$refs.form', this.$refs.form)
      this.$refs.form && this.$refs.form.resetValidation()
      this.form.password = ''
      this.form.new_password = ''
      this.form.re_new_password = ''
    },
    doResetPass (form) {
      console.log('form---', form)
      let data = {
        ...api.getPublicPara('v2'),
        pwd: md5(form.password),
        new_pwd: md5(form.new_password)
      }
      this.loading = true
      api.axiosApiRequests(api.apiUrlList.platform.user.reset_password, data)
        .then(res => {
          if (res.code == common.resCode.OK) {
            this.$toast('修改成功')
            this.showModifyDialog = false
          } else if (res.code == 99999) {
            this.$toast.fail(res.msg)
          } else {
            this.$toast.fail('修改失败')
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast.fail('网络错误')
        })
        .finally(() => {
          this.loading = false
        })
    },
    doCancel () {
      this.showModifyDialog = false
      // this.$refs.form && this.$refs.form.resetValidation()
    },
    onFail() {
      console.log('klfjdslk;')
    },
    doLogout () {
      let data = {
        ...api.getPublicPara('v2')
      }
      api.axiosApiRequests(api.apiUrlList.platform.user.logout, data)
        .then(res => {
          console.log(res)
          if (res.code == common.resCode.OK || res.code == common.resCode.Unauthorized) {
            localStorage.removeItem('accessToken')
            this.$store.commit('commitAccessToken')
            // location.href = location.protocol + '//' + location.host + '/#/login'
            this.$router.replace('/login')
          } else {
            this.$toast.fail('退出失败')
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast.fail('网络错误')
        })
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.account-detail {
  display: flex;
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;;-->*/
  &-main {
    position: relative;
    @extend .content-wrap-gap;
    width: 100%;
    .account-detail-bnt-wrap{
      box-sizing: border-box;
      padding: 2rem 1rem;
      .bnt-top-wrap{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        /*.van-button{*/
          /*!*width: 46%;*!*/
        /*}*/

      }
      .bnt-bottom-wrap{
        margin-top: 1rem;
        /*.van-button{*/
          /*background: rgba(0, 0, 0, 0.1);*/
        /*}*/
      }

    }
  }
  .modify-footer{
    margin: 16px;
    display: flex;
    justify-content: space-between;
    .van-button{
      width: 45%;
    }
  }
  .cancel{
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
